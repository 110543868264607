import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AddSource from './pages/AddSource';
import AllSources from './pages/AllSources';
import Collection01 from './pages/Collection01';
import Collection02 from './pages/Collection02';
import Collection03 from './pages/Collection03';
import Collection04 from './pages/Collection04';
import CollectionData from './pages/CollectionData';
import Yesterday from './pages/Yesterday';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-source" element={<AddSource />} />
        <Route path="/all-sources" element={<AllSources />} />
        <Route path='/collection-01' element={<Collection01 />} />
        <Route path='/collection-02' element={<Collection02 />} />
        <Route path='/collection-03' element={<Collection03 />} />
        <Route path='/collection-04' element={<Collection04 />} />
        <Route path='/collection-data' element={<CollectionData />} />
        <Route path='/yesterday' element={<Yesterday />} />
      </Routes>
    </Router>
  );
}

export default App;