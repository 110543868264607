import React, { useEffect, useState, useRef, useCallback} from 'react';
import supabase from '../lib/supabaseClient';
import withAuth from './withAuth'

function NewsFeed({session,  selectedSource, setSelectedSource, contentType}) {
    const [briefs, setBriefs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const INITIAL_LOAD = 10;
    const LOAD_MORE = 10;

    const getBriefsRef = useRef();

    const [sources, setSources] = useState({});
    const [sourcesLoaded, setSourcesLoaded] = useState(false);

        // 使用 useCallback 包裹 getSources 函数
    const getSources = useCallback(async (selectedSource) => {
      try {
        if (selectedSource) {
          const { data, error } = await supabase
            .from("user_source")
            .select("user_id, source_id")
            .eq("source_id", selectedSource);

          if (error) {
            console.error('Error fetching sources:', error);
            return;
          }

          const source_ids = data.map(item => item.source_id);
          setSources(source_ids);
          setSourcesLoaded(true);  // 标记 sources 已加载完成
        } else {
          const { data, error } = await supabase
            .from("user_source")
            .select("user_id, source_id");
          
          if (error) {
            console.error('Error fetching sources:', error);
            return;
          }

          const source_ids = data.map(item => item.source_id);
          setSources(source_ids);
          setSourcesLoaded(true);  // 标记 sources 已加载完成
        }

        setBriefs([]); // 重置 briefs
        setOffset(0); // 重置 offset
        setHasMore(true); // 重置 hasMore
        // setSelectedSource(null);  // 假设你有一个状态来存储当前选中的来源
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }, []);

    useEffect(() => {
        getSources(selectedSource);
    }, [selectedSource, getSources]);
    

    getBriefsRef.current = async (limit) => {
        if (isLoading) return;
        setIsLoading(true);
        const { data, error } = await supabase.from("brief")
            .select(`
                create_dt,
                title,
                content_url,
                source_id,
                source: source_id (source_name)
            `)
            .eq('content_type_1', contentType)
            .in('source_id', sources)
            .order('create_dt', {ascending: false}).range(offset, offset + limit - 1);
        
        if (error) {
            console.error(error);
            setIsLoading(false);
            return;
        }

        if (data.length < limit) {
            setHasMore(false);
        }

        setBriefs(prevBriefs => [...prevBriefs, ...data]);
        setOffset(prevOffset => prevOffset + data.length);
        setIsLoading(false);
    };

    useEffect(() => {
        if (sourcesLoaded && sources.length > 0) {
            getBriefsRef.current(INITIAL_LOAD);
        }
    }, [sourcesLoaded, sources]); // 依赖项包括 sourcesLoaded 和 sources

    const loadMore = () => {
        getBriefsRef.current(LOAD_MORE);
    };

    console.log('print briefs')
    console.log(briefs)
  
  return (
    <div>
      <div className="news-card-list">
        {briefs.map((brief, index) => (
          <a href = {brief.content_url} target="_blank" rel="noreferrer" className='news_card'>
            <h5 className = 'news_card_headline'>{brief.title}</h5>
            <div className = 'news_card_meta'>
              <p className = 'news_card_date'>{brief.create_dt}</p>
              <p className = 'news_card_source'>{brief.source.source_name}</p>
            </div>
            
            <p className = 'news_card_content'></p>
            {/* <img src= {testIMG} alt = 'pics'></img> */}
            
            <p className = 'news_card_tag_list'>
              {/* <span className='news_card_tag'>标签A</span>
              <span className='news_card_tag'>呼声</span> */}
            </p>
          </a>
        ))}
      </div>
      {hasMore && !isLoading && (
        <button onClick={loadMore} className="load-more-button button-link">
          显示更多
        </button>
      )}
      {isLoading && <div>加载中...</div>}
    </div>
  );
}

export default withAuth(NewsFeed);


