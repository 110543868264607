import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga4';
import './Home.css';
import CollectionCards from '../components/CollectionCards';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import Nav from '../components/nav';
import withAuth from '../components/withAuth';

ReactGA.initialize('G-FK4YHLDPRT');

function Home({session}) {
  const [selectedSource, setSelectedSource] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  console.log(session);

  return (
    <div className="App">
      <Header />
      <div className='header_spacer'></div>
      <div className='sidebar'>
        <Nav />
        <div className='notice'>
          <SideBar onSelectSource={setSelectedSource} />
        </div>
      </div>
      <div className='news_card_conatiner'>
        <h3 className='collection_title'>Collection - 其他（未分类）</h3>
        <CollectionCards selectedSource={selectedSource} setSelectedSource={setSelectedSource} contentType={4}/>
      </div>
    </div>
  );
}

export default withAuth(Home);