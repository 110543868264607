import React from 'react';
import { Link } from 'react-router-dom';

import './nav.css'

function Nav() {
  
  return (
    <div className="nav_container">
      <p className='nav_title'>导航</p>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/collection-01">合辑-实践经验</Link></li>
        <li><Link to="/collection-02">合辑-模型发现</Link></li>
        <li><Link to="/collection-data">合辑-数据产品</Link></li>
        <li><Link to="/collection-04">其他（未分类）</Link></li>
        <li><Link to="/yesterday">昨日热点</Link></li>
      </ul>
    </div>
  );
}

export default Nav;