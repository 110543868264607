import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga4';
import supabase from '../lib/supabaseClient';
import ReactMarkdown from 'react-markdown'; // 新增导入

import './Home.css';
import Header from '../components/Header';
import Nav from '../components/nav';
import './Yesterday.css'

ReactGA.initialize('G-FK4YHLDPRT');

function Home() {

    const [topNews, setTopNews] = useState(null);
  
    useEffect(() => {
        fetchTopNews();
    }, []);
    
    async function fetchTopNews() {
        const { data, error } = await supabase
            .from("top_news")
            .select('*')
            .order('news_date', {ascending: false })
            .limit(1);
    
        console.log(data)
    
        if (error) {
            console.error('Error fetching sources:', error);
            return;
        }
        
        setTopNews(data)

    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <div className="App">
        <Header />
        <div className='header_spacer'></div>
        <div className='sidebar'>
            <Nav />
        </div>
        <div className='news_card_conatiner'>
            <h3 className='collection_title'>Collection - 昨日热点（每日10点后更新）</h3>
            <div className='top_news_container'>
                {topNews && topNews[0] ? (
                    <>
                        <p className='news_date'>{topNews[0]['news_date']}</p>
                        <ReactMarkdown className='news_content'>{topNews[0]['news_content']}</ReactMarkdown> {/* 更新此处 */}
                    </>
                ) : (
                    <p>数据加载中</p>
                )}
            </div>
        </div>
        </div>
    );
}

export default Home;