import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import supabase from '../lib/supabaseClient';
import './SideBar.css'
import { data_product_sources } from './CollectionDataCards';

function SideBar({onSelectSource}) {
  const [groupedSources, setGroupedSources] = useState({});
  const [selectedSource, setSelectedSource] = useState(null);

  useEffect(() => {
    getSources();
  }, []);
  
  async function getSources() {
    const { data, error } = await supabase
      .from("source")
      .select(`id,
                source_type,
                source_name
              `)
      .in('id', data_product_sources)
      .order('source_name', { ascending: true });

    console.log(data)

    if (error) {
      console.error('Error fetching sources:', error);
      return;
    }

    // 按 source_type 分组
    const grouped = data.reduce((acc, source) => {
      if (!acc[source.source_type]) {
        acc[source.source_type] = [];
      }
      acc[source.source_type].push(source);
      return acc;
    }, {});

    setGroupedSources(grouped);
  }

  return (
    <div className="side_bar">
      <p className='added_source'>监控中站点</p>
      {Object.entries(groupedSources).map(([sourceType, sources]) => (
        <div key={sourceType}>
          <h5 className='source_type'>{sourceType}</h5>
          <ul className='source_ul'>
            {sources.map((source, index) => (
              <li key={index} 
                  className={`source ${selectedSource === source.id ? 'selected' : ''}`} 
                  onClick={() => {
                    setSelectedSource(source.id);
                    onSelectSource(source.id);
                  }}
              >
                <span>{source.source_name}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default SideBar;