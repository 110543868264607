import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Header from '../components/Header';
import withAuth from '../components/withAuth';
import supabase from '../lib/supabaseClient';
import './AllSources.css'; 

function AllSources({session}) {
    const [sources, setSources] = useState([]);
    const [userSources, setUserSources] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchUserSources = useCallback(async () => {
        try {
            const { data, error } = await supabase
                .from('user_source')
                .select('source_id')
                .eq('user_id', session.user.id);

            if (error) throw error;

            setUserSources(data.map(item => item.source_id));
        } catch (error) {
            console.error('Error fetching user sources:', error);
        }
    }, [session.user.id]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        fetchSources();
        fetchUserSources();
    }, [fetchUserSources]);

    async function fetchSources() {
        try {
            const { data, error } = await supabase
                .from('source')
                .select('*')
                .order('source_name',  { ascending: true });

            if (error) throw error;

            setSources(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching sources:', error);
            setError('获取数据源失败，请稍后再试。');
            setIsLoading(false);
        }
    }

    async function toggleUserSource(sourceId) {
        const isSelected = userSources.includes(sourceId);
        try {
            if (isSelected) {
                // 如果已选中，则删除
                await supabase
                    .from('user_source')
                    .delete()
                    .eq('user_id', session.user.id)
                    .eq('source_id', sourceId);
                setUserSources(userSources.filter(id => id !== sourceId));
            } else {
                // 如果未选中，则添加
                await supabase
                    .from('user_source')
                    .insert({ user_id: session.user.id, source_id: sourceId });
                setUserSources([...userSources, sourceId]);
            }
        } catch (error) {
            console.error('Error updating user source:', error);
            alert('更新失败，请重试。');
        }
    }

    if (isLoading) return <div>加载中...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="App">
            <Header />
            <div className='header_spacer'></div>
            <div className='all-source_container'>
                <div className="all-sources">
                    <h2>选择数据源</h2>
                    <p>（勾选所需数据源）</p>
                    <p className='add_source'><Link to="/add-source" className="button-link">添加数据源</Link></p>
                    <table>
                        <thead>
                            <tr>
                                <th>名称</th>
                                <th>状态</th>
                                <th>类型</th>
                                <th>URL</th>
                                <th>发送者邮箱</th>
                                <th>解析 URL</th>
                                <th>选择</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sources.map((source) => (
                                <tr key={source.id}>
                                    <td>{source.source_name}</td>
                                    <td>
                                        <span 
                                            className={`status-dot ${source.is_on ? 'status-on' : 'status-off'}`} 
                                            title={source.is_on ? '启用' : '禁用'}
                                        ></span>
                                    </td>
                                    <td>{source.source_type}</td>
                                    <td>{source.source_url}</td>
                                    <td>{source.source_sender_email || '-'}</td>
                                    <td>{source.parse_rule}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={userSources.includes(source.id)}
                                            onChange={() => toggleUserSource(source.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default withAuth(AllSources);
