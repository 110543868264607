import React, { useEffect, useState, useRef, useCallback} from 'react';
import supabase from '../lib/supabaseClient';


export const data_product_sources = [
  'ecc956d5-a657-4ca1-9138-bdc55e064f50', // 微软科技
  'a9ce69cc-f0c9-445f-ac62-af34edb6944c', // SuperSonic超音数
  '74e45897-ed51-4a5f-98b2-2fd432e527f8', // 数栈研习社
  'a151bfc9-4b4f-4c91-bac8-0b0c216bba81', // 魔搭ModelScope社区
  '33ba1b45-457d-4a30-98af-0d32af93cfe2', // 瓴羊DaaS
  '4e15f603-96c6-4eaf-8d69-db3bcf148066', // Kyligence
  '869c9cb4-2016-4b07-bce0-66ef0e000542', // 硅基流动
  '69d4517b-4c84-4b19-97ca-28580fec5d52', // 通义
  '22d6e458-098b-4def-8b2b-d7a311395ede', // LangChain Blog
  '27d1c5a6-1cf2-41d8-8874-546206e0f3c1', // Gamma.app
  'e12506bf-1a46-44b5-8d5b-b1b259b5ffbe', // 观远数据
]
function NewsFeed({selectedSource, setSelectedSource}) {
    const [briefs, setBriefs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const INITIAL_LOAD = 10;
    const LOAD_MORE = 10;

    const getBriefsRef = useRef();

    const [sources, setSources] = useState({});
    const [sourcesLoaded, setSourcesLoaded] = useState(false);

        // 使用 useCallback 包裹 getSources 函数
    const getSources = useCallback(async (selectedSource) => {
      try {
        if (selectedSource) {
          const { data, error } = await supabase
            .from("source")
            .select("id, source_name")
            .eq("id", selectedSource);

          if (error) {
            console.error('Error fetching sources:', error);
            return;
          }

          const source_ids = data.map(item => item.id);
          setSources(source_ids);
          setSourcesLoaded(true);  // 标记 sources 已加载完成
        } else {
          // const { data, error } = await supabase
          //   .from("user_source")
          //   .select("user_id, source_id");
          
          // if (error) {
          //   console.error('Error fetching sources:', error);
          //   return;
          // }

          // const source_ids = data.map(item => item.source_id);
          setSources(data_product_sources);
          setSourcesLoaded(true);  // 标记 sources 已加载完成
        }

        setBriefs([]); // 重置 briefs
        setOffset(0); // 重置 offset
        setHasMore(true); // 重置 hasMore
        // setSelectedSource(null);  // 假设你有一个状态来存储当前选中的来源
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }, []);

    useEffect(() => {
        getSources(selectedSource);
    }, [selectedSource, getSources]);


    getBriefsRef.current = async (limit) => {
        if (isLoading) return;
        setIsLoading(true);

        const { data, error } = await supabase.from("brief")
            .select(`
                create_dt,
                title,
                content_url,
                source_id,
                source: source_id (source_name)
            `)
            .in('source_id', sources)
            .order('create_dt', {ascending: false}).range(offset, offset + limit - 1);
        
        if (error) {
            console.error(error);
            setIsLoading(false);
            return;
        }

        if (data.length < limit) {
            setHasMore(false);
        }

        setBriefs(prevBriefs => [...prevBriefs, ...data]);
        setOffset(prevOffset => prevOffset + data.length);
        setIsLoading(false);
    };

    useEffect(() => {
      if (sourcesLoaded && sources.length > 0) {
          getBriefsRef.current(INITIAL_LOAD);
      }
  }, [sourcesLoaded, sources]); // 依赖项包括 sourcesLoaded 和 sources


    const loadMore = () => {
        getBriefsRef.current(LOAD_MORE);
    };

    console.log('print briefs')
    console.log(briefs)
  
  return (
    <div>
      <div className="news-card-list">
        {briefs.map((brief, index) => (
          <a href = {brief.content_url} target="_blank" rel="noreferrer" className='news_card'>
            <h5 className = 'news_card_headline'>{brief.title}</h5>
            <div className = 'news_card_meta'>
              <p className = 'news_card_date'>{brief.create_dt}</p>
              <p className = 'news_card_source'>{brief.source.source_name}</p>
            </div>
            
            <p className = 'news_card_content'></p>
            {/* <img src= {testIMG} alt = 'pics'></img> */}
            
            <p className = 'news_card_tag_list'>
              {/* <span className='news_card_tag'>标签A</span>
              <span className='news_card_tag'>呼声</span> */}
            </p>
          </a>
        ))}
      </div>
      {hasMore && !isLoading && (
        <button onClick={loadMore} className="load-more-button button-link">
          显示更多
        </button>
      )}
      {isLoading && <div>加载中...</div>}
    </div>
  );
}

export default NewsFeed;


